/* eslint-disable max-lines */
import { Tokens } from '@vizir-banking/design-system';

const boxShadowColor = 'rgba(0, 0, 0, 0.08)';

export const designSystemTokens: Tokens = {
  palette: {
    primary: {
      '$color-brand-primary-regular': '#B6C243',
      '$color-brand-primary-darkest': '#B6C243',
      '$color-brand-primary-dark': '#B6C243',
      '$color-brand-primary-light': '#B6C243',
      '$color-brand-primary-lightest': '#B6C243',
      '$color-brand-primary-gradient': ['#B6C243', '#B6C243'],
    },
    secondary: {
      '$color-brand-secondary-regular': '#149EB8',
      '$color-brand-secondary-darkest': '#149EB8',
      '$color-brand-secondary-dark': '#149EB8',
      '$color-brand-secondary-light': '#149EB8',
      '$color-brand-secondary-lightest': '#149EB8',
      '$color-brand-secondary-gradient': ['#149EB8', '#149EB8'],
    },
    neutral: {
      '$color-neutral-darkest': '#181818',
      '$color-neutral-darker': '#666666',
      '$color-neutral-dark': '#999999',
      '$color-neutral-medium': '#CCCCCC',
      '$color-neutral-light': '#E1E1E1',
      '$color-neutral-lighter': '#F5F5F5',
      '$color-neutral-lightest': '#FFFFFF',
    },
    error: {
      '$color-feedback-error-darkest': '#811E18',
      '$color-feedback-error-dark': '#B71E15',
      '$color-feedback-error-medium': '#EA534A',
      '$color-feedback-error-light': '#EF7D76',
      '$color-feedback-error-lightest': '#F4A8A4',
    },
    success: {
      '$color-feedback-success-darkest': '#327B66',
      '$color-feedback-success-dark': '#419F85',
      '$color-feedback-success-medium': '#57BA9E',
      '$color-feedback-success-light': '#7DCAB4',
      '$color-feedback-success-lightest': '#A1D8C9',
    },
    warning: {
      '$color-feedback-attention-darkest': '#CB7A01',
      '$color-feedback-attention-dark': '#F1960E',
      '$color-feedback-attention-medium': '#F4AC40',
      '$color-feedback-attention-light': '#F7C06E',
      '$color-feedback-attention-lightest': '#FAD59E',
    },
    info: {
      '$color-feedback-info-darkest': '#1C607D',
      '$color-feedback-info-dark': '#2581A7',
      '$color-feedback-info-medium': '#43AAD5',
      '$color-feedback-info-light': '#6DBDDE',
      '$color-feedback-info-lightest': '#A3D6EB',
    },
  },
  boxShadow: {
    '$shadow-level-1': {
      offsetY: '4px',
      offsetX: '0px',
      blur: '16px',
      color: boxShadowColor,
    },
    '$shadow-level-2': {
      offsetY: '8px',
      offsetX: '0px',
      blur: '24px',
      color: boxShadowColor,
    },
    '$shadow-level-3': {
      offsetY: '16px',
      offsetX: '0px',
      blur: '32px',
      color: boxShadowColor,
    },
    '$shadow-level-4': {
      offsetY: '16px',
      offsetX: '0px',
      blur: '48px',
      color: boxShadowColor,
    },
  },
  fontSize: {
    '$font-size-xxs': '12px',
    '$font-size-xs': '14px',
    '$font-size-s': '16px',
    '$font-size-m': '18px',
    '$font-size-l': '20px',
    '$font-size-xl': '24px',
    '$font-size-xxl': '32px',
    '$font-size-xxxl': '48px',
  },
  fontStyle: { '$font-style-italic': 'italic' },
  fontWeight: {
    '$font-weight-light': 400,
    '$font-weight-regular': 500,
    '$font-weight-medium': 600,
    '$font-weight-bold': 700,
  },
  lineHeight: {
    '$line-height-tight': '100%',
    '$line-height-medium': '120%',
    '$line-height-loose': '150%',
    '$line-height-veryloose': '200%',
  },
  borderRadius: {
    '$border-radius-none': '0px',
    '$border-radius-xs': '4px',
    '$border-radius-s': '8px',
    '$border-radius-m': '16px',
    '$border-radius-l': '24px',
    '$border-radius-pill': '500px',
    '$border-radius-circular': '50%',
  },
  borderWidth: {
    '$border-width-none': '0px',
    '$border-width-hairline': '1px',
    '$border-width-thin': '2px',
    '$border-width-thick': '4px',
    '$border-width-heavy': '8px',
  },
  spacingHorizontal: {
    '$spacing-horizontal-quark': '4px',
    '$spacing-horizontal-nano': '8px',
    '$spacing-horizontal-xxxs': '16px',
    '$spacing-horizontal-xxs': '24px',
    '$spacing-horizontal-xs': '32px',
    '$spacing-horizontal-s': '40px',
    '$spacing-horizontal-m': '48px',
    '$spacing-horizontal-l': '64px',
    '$spacing-horizontal-xl': '80px',
  },
  textDecoration: { '$text-decoration-underline': 'underline' },
  opacity: {
    '$opacity-level-semiopaque': 0.8,
    '$opacity-level-medium': 0.64,
    '$opacity-level-light': 0.32,
    '$opacity-level-lighter': 0.16,
    '$opacity-level-semitransparent': 0.08,
    '$opacity-level-transparent': 0,
  },
  spacingVertical: {
    '$spacing-vertical-quark': '4px',
    '$spacing-vertical-nano': '8px',
    '$spacing-vertical-xxxs': '16px',
    '$spacing-vertical-xxs': '24px',
    '$spacing-vertical-xs': '32px',
    '$spacing-vertical-s': '40px',
    '$spacing-vertical-m': '48px',
    '$spacing-vertical-l': '56px',
    '$spacing-vertical-xl': '64px',
    '$spacing-vertical-xxl': '80px',
    '$spacing-vertical-xxxl': '120px',
    '$spacing-vertical-huge': '160px',
    '$spacing-vertical-giant': '200px',
  },
  spacingSquish: {
    '$spacing-squish-quark': { vertical: '4px', horizontal: '8px' },
    '$spacing-squish-nano': { vertical: '8px', horizontal: '16px' },
    '$spacing-squish-xs': { vertical: '16px', horizontal: '24px' },
    '$spacing-squish-s': { vertical: '16px', horizontal: '32px' },
  },
  spacingInset: {
    '$spacing-inset-quark': '4px',
    '$spacing-inset-nano': '8px',
    '$spacing-inset-xs': '16px',
    '$spacing-inset-s': '24px',
    '$spacing-inset-m': '32px',
    '$spacing-inset-l': '40px',
  },
  sizes: {
    '$size-quark': '4px',
    '$size-nano': '8px',
    '$size-xxxs': '16px',
    '$size-xxs': '24px',
    '$size-xs': '32px',
    '$size-s': '40px',
    '$size-m': '48px',
    '$size-l': '56px',
    '$size-xl': '64px',
    '$size-xxl': '80px',
    '$size-xxxl': '120px',
    '$size-huge': '160px',
    '$size-giant': '200px',
  },
};
